import './App.css';
import { useState } from 'react';
import axios from 'axios';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import * as XLSX from 'xlsx';

function App() {
  const [isLoaded, setIsLoaded] = useState(false);
  const [products, setProducts] = useState([]);
  const [selectedStore, setSelectedStore] = useState('plein');
  const [searchText, setSearchText] = useState('');
  const [selectedLimit, setSelectedLimit] = useState(0);
  const [searchResultText, setSearchResultText] = useState('Ürünleri görüntülemek için arama alanını kullanabilirsiniz.');
  const [file, setFile] = useState(null);

  const fetchProducts = async () => {
    try {
      let url = `https://digicrawler-api.digitson.com.tr/api/getProductList/${selectedStore}?searchText=${searchText}`;

      if (selectedLimit > 0) {
        url = url + '&withLimit=' + selectedLimit;
      }

      setSearchResultText('Ürünler aranıyor.');

      const response = await axios.get(url);

      console.log(response.status)
      if (response.status < 300) {
        setIsLoaded(false);
        setProducts(response.data);

        setSearchResultText(`Ürün arama işlemi tamamlandı ${response.data.length} adet ürün bulundu`);
      } else {
        setIsLoaded(false);
        setSearchResultText('Arama sırasında bir hata oluştu lütfen tekrar deneyiniz.');
      }

    } catch {
      setIsLoaded(false);
      setSearchResultText('Arama sırasında bir hata oluştu lütfen tekrar deneyiniz.');
    }
  };

  const handleChangeStore = (event) => {
    const index = event.nativeEvent.target.selectedIndex;
    setSelectedStore(event.nativeEvent.target[index].text.toLowerCase());
  };

  const handleLimit = (event) => {
    const index = event.nativeEvent.target.selectedIndex;
    setSelectedLimit(parseInt(event.nativeEvent.target[index].text.toLowerCase()));
  };

  const handleSearchInputChange = (e) => {
    setSearchText(e.target.value);
  };

  const getSearchData = () => {
    setIsLoaded(true);
    setProducts([]);

    fetchProducts();
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const exportToExcel = () => {
    const ws = XLSX.utils.json_to_sheet(products);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Products');
    XLSX.writeFile(wb, 'products.xlsx');
  };

  const importAndAppendToExcel = () => {
    if (!file) return;

    const reader = new FileReader();
    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      const firstSheet = workbook.Sheets[workbook.SheetNames[0]];

      const existingData = XLSX.utils.sheet_to_json(firstSheet);
      const updatedData = existingData.concat(products);

      const newWs = XLSX.utils.json_to_sheet(updatedData);
      const newWb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(newWb, newWs, 'Products');
      XLSX.writeFile(newWb, 'updated_products.xlsx');
    };
    reader.readAsArrayBuffer(file);
  };

  const carouselSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    vertical: false,
  };

  return (
    <div className="App w-100">
      <div className="container d-flex flex-column justify-content-evenly">
        <div className="row d-flex justify-content-center align-items-center">
          <div className="col-md-6 mt-5">
            <input type="file" onChange={handleFileChange} className="form-control mb-3" />
            <div className='p-2 d-flex justify-content-center align-items-center'>
              <button onClick={exportToExcel} className="btn btn-primary mb-3">Excel'e Aktar</button>
              <button onClick={importAndAppendToExcel} className="btn btn-secondary mb-3 ms-3">Varolan Excel Dosyasına Ekle</button>
            </div>
            <hr />
            <select className="form-select mb-3" onChange={(e) => { handleChangeStore(e) }} aria-label="Default select example">
              <option selected>Site seçiniz (Arama yapılacak site adını seçiniz.)</option>
              <option value="1">Auchan</option>
              <option value="2">Carrefour</option>
              <option value="3">Plein</option>
              <option value="4">Ah</option>
            </select>
            <select className="form-select mb-3" onChange={(e) => { handleLimit(e) }} aria-label="Default select example">
              <option selected>Limit Belirle (Aramadan dönecek ürün sayısını limitler.)</option>
              <option value="1">1</option>
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="30">30</option>
              <option value="50">40</option>
              <option value="100">100</option>
            </select>
            <div className="form">
              <i className="fa fa-search"></i>
              <input type="text" className="form-control form-input" onChange={(e) => { handleSearchInputChange(e) }} placeholder="Ürün ara" />
              <span className="left-pan"><i onClick={() => getSearchData()} className="fa fa-sync-alt"></i></span>
            </div>
          </div>
        </div>
        {products.length === 0 ? (
          <div className="row d-flex justify-content-center align-items-center">
            <span>{searchResultText}</span>
            {isLoaded ? (<div className="spinner-border text-success" role="status">
              <span className="sr-only"></span>
            </div>) : ''}
          </div>
        ) : (
          <div className="row d-flex justify-content-center align-items-center">
            <div>{searchResultText}</div>
            <div className="mt-5 row slider-container">
              <Slider {...carouselSettings}>
                {products.map((product) => (
                  <div key={product.id} style={{ padding: 10, margin: '0 20px', width: "20%" }} >
                    <div style={{ height: '450px', overflow: 'scroll' }} className='d-flex justify-content-center align-items-center flex-column'>
                      <img src={product.image} alt={product.name} style={{ width: "85%", objectFit: 'contain' }} />
                      <h6 className='truncate'>{product.name}</h6>
                      <p>{product.price}</p>
                      <p>{product.originalPrice}</p>
                      <hr></hr>
                    </div>
                    <div dangerouslySetInnerHTML={{ __html: product.description || '<div></div>' }} style={{ height: '80px', overflow: 'scroll' }} className='border border-1 border-secondary' />
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default App;






// import './App.css';
// import { useState } from 'react';
// import axios from 'axios';
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
// import Slider from "react-slick";



// function App() {
//   const [isLoaded,setIsLoaded] = useState(false);
//   const [products, setProducts] = useState([]);
//   const [findedProductCount, setFindedProductCount] = useState(0);
//   const [selectedStore, setSelectedStore] = useState('plein');
//   const [searchText, setSearchText] = useState('');
//   const [selectedLimit, setSelectedLimit] = useState(0);
//   const [searchResultText, setSearchResultText] = useState('Ürünleri görüntülemek için arama alanını kullanabilirsiniz.');

//   const fetchProducts = async () => {
//     try {
//         let url= `https://digicrawler-api.digitson.com.tr/api/getProductList/${selectedStore}?searchText=${searchText}`;

//         if(selectedLimit > 0){
//           url = url + '&withLimit=' + selectedLimit;
//         }

//         const response = await axios.get(url);

//         if(response.status < 300){
//           setFindedProductCount(response.data.length);
//           setIsLoaded(false);
//           setProducts(response.data);
//           setSearchResultText('Ürünler aranıyor.')
//         }else{
//           setIsLoaded(false);
//           setSearchResultText('Arama sırasında bir hata oluştu lütfen tekrar deneyiniz.')
//         }

//     } catch (error) {
//         console.error('Veri çekme hatası:', error);
//     }
//   };

//   const handleChangeStore = (event) => {
//   const index = event.nativeEvent.target.selectedIndex;
//   setSelectedStore(event.nativeEvent.target[index].text.toLowerCase())
//   };

//   const handleLimit = (event) => {
//   const index = event.nativeEvent.target.selectedIndex;
//   setSelectedLimit(parseInt(event.nativeEvent.target[index].text.toLowerCase()))
//   };

//   const handeSearchInputChange = (e)=>{
//     setSearchText(e.target.value)
//   }

//   const getSearchData = () => {
//   setIsLoaded(true);
//   setProducts([]);

//   fetchProducts();
//   }

//   const carouselSettings = {
//   dots: true,
//   infinite: true,
//   speed: 500,
//   slidesToShow: 3,
//   slidesToScroll: 1,
//   vertical: false,
//   };

//   return (
//   <div className="App">
//     <div className="container">
//       <div className="row height d-flex justify-content-center align-items-center">
//         <div className="col-md-6">
//             <select class="form-select mb-3" onChange={(e)=>{handleChangeStore(e)}} aria-label="Default select example">
//               <option selected>Site seçiniz (Arama yapılacak site adını seçiniz.)</option>
//               <option value="1">Auchan</option>
//               <option value="2">Carrefour</option>
//               <option value="3">Plein</option>
//               <option value="4">Ah</option>
//             </select>
//             <select class="form-select mb-3" onChange={(e)=>{handleLimit(e)}} aria-label="Default select example">
//               <option selected>Limit Belirle (Aramadan dönecek ürün sayısını limitler.)</option>
//               <option value="1">1</option>
//               <option value="5">5</option>
//               <option value="10">10</option>
//               <option value="20">20</option>
//               <option value="30">30</option>
//               <option value="50">40</option>
//               <option value="100">100</option>
//             </select>
//           <div className="form">
//             <i className="fa fa-search"></i>
//             <input type="text" className="form-control form-input" onChange={(e)=>{handeSearchInputChange(e)}} placeholder="Ürün ara"/>
//             <span className="left-pan"><i onClick={() =>getSearchData()} className="fa fa-sync-alt"></i></span>
//           </div>
//         </div>
//       </div>
//       {products.length === 0 ? (
//         <div className="row height d-flex justify-content-center align-items-center">
//           <span>{searchResultText}</span>
//           {isLoaded ? (<div className="spinner-border text-success" role="status">
//             <span className="sr-only"></span>
//           </div>) : ''}
//         </div>
//       ): (
//         <div className="row height d-flex justify-content-center align-items-center">
//         <div>Ürün arama işlemi tamamlandı {findedProductCount} adet ürün bulundu</div>
//         <div className="mt-5 row slider-container">
//           <Slider {...carouselSettings}>
//             {products.map((product) => (
//               <div key={product.id} style={{ padding: 10, margin: '0 20px', width: "20%" }} >
//                 <div style={{height: '450px', overflow: 'scroll'}} className='d-flex justify-content-center align-items-center flex-column'>
//                   <img src={product.image} alt={product.name} style={{ width: "85%", objectFit: 'contain' }} />
//                   <h6 className='truncate'>{product.name}</h6>
//                   <p>{product.price}</p>
//                   <p>{product.originalPrice}</p>
//                   <hr></hr>
//                 </div>
//                 <div dangerouslySetInnerHTML={{ __html: product.description || '<div></div>' }} style={{height: '80px', overflow: 'scroll'}} className='border border-1 border-secondary' />
//               </div>
//             ))}
//           </Slider>
//         </div>
//       </div>        
//       )}
//       </div>
//   </div>
//   );
// }

// export default App;